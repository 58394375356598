import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import AnchorList from "../anchor-list/anchor-list.component";
import Leaf from "../../assets/HSBC_Pictogram_Sustainable Growth_Black.png";
import Book from "../../assets/HSBC_Pictogram_Book Education_Black.png";
import LAptop from "../../assets/HSBC_Pictogram_Internet Banking_Black.png";
import GreenResourcesData from "../../data/green-resources-datas.json";

import "../../styles/app.scss";

export default function GreenResources() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.slice(1);

    // Perform actions based on the hash value
    if (hash === "resources" && typeof window !== "undefined") {
      window.scrollTo({ bottom: 0 });
    }
  }, [location.hash]);

  return (
    <section id="resources" className="newsroom-page page-section green__section row">
      <div className="information-container">
        <h2 className="green-section__title">Resources</h2>

        <div className="splitted-section">
          <div className="splitted-section--third">
            <div className="splitted-section__title-section">
              <img src={Leaf} alt="Sustainability articles" />
              <h3> Sustainability articles </h3>
            </div>
            <div className="recent-articles">
              <AnchorList additionalClassName={"news-list"} anchorItems={GreenResourcesData.articles} isLink={true} />
            </div>
          </div>
          <div className="splitted-section--third">
            <div className="splitted-section__title-section">
              <img src={Book} alt="E-magazines" />
              <h3>E-magazines</h3>
            </div>
            <div className="recent-articles">
              <AnchorList additionalClassName={"news-list"} anchorItems={GreenResourcesData.magazines} isLink={true} />
            </div>
          </div>
          <div className="splitted-section--third">
            <div className="splitted-section__title-section">
              <img src={LAptop} alt="Useful links" />
              <h3>Useful links</h3>
            </div>
            <div className="recent-articles">
              <AnchorList additionalClassName={"news-list"} anchorItems={GreenResourcesData.links} isLink={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
