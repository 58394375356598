import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import Layout from "../components/layout";
import "../styles/app.scss";
import GreenHero from "../components/green-page/green-hero.component";
import GreenCard from "../components/green-page/green-card.component";
import GreenResources from "../components/green-page/green-resources.component";
import GreenSectionWithCards from "../components/green-page/green-section-with-card.component";
import Coastal from "../assets/images/coastal.jpeg";
import EnergyEfficient from "../assets/images/energy-efficient.jpeg";
import ComeAndTalk2 from "../assets/images/come-and-talk2.jpg";
import HSBCCan from "../assets/images/hsbc-can.png";
import { triggerTealiumEvent } from "../utils/tealiumUtils";

export default function Green() {
  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageName: "green",
      pageType: "product information",
      pageSubCategory: "green",
      rawDataLayer: "10451v83",
    });
  }

  const handleClick = ({ sectionName, clickedText }) => {
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        pageName: "green",
        eventCategory: "content",
        eventAction: "onsite",
        eventContent: `${sectionName} | green : ${clickedText}`,
        pageType: "product information",
        pageSubCategory: "green",
        rawDataLayer: "10451v86",
      });
    }
  };

  useEffect(() => {
    const isGreenPage = location.pathname.includes("green" || "energy-performance-certificate");
    const elem = document.querySelector(".page");

    if (elem) {
      if (isGreenPage) {
        elem.classList.add("page--grey");
      } else {
        elem.classList.remove("page--grey");
      }
    }
  }, [location]);

  const cardItems = [
    {
      image: EnergyEfficient,
      title: "How to make homes more energy efficient",
      description: "Read our tips on how to help the planet while also lowering bills and saving money.",
      href: "https://www.hsbc.co.uk/financial-fitness/everyday-budgeting/how-to-make-your-home-energy-efficient/",
    },
    {
      image: Coastal,
      title: "What could climate change mean for mortgages?",
      description:
        "Learn how risks like flooding and coastal erosion could affect your customers mortgage application.",
      href: "https://www.hsbc.co.uk/mortgages/could-climate-change-impact-your-mortgage-application/",
    },
  ];

  const cardItems2 = [
    {
      image: ComeAndTalk2,
      title: "",
      description: (
        <>
          Being green and helping the environment comes in many forms, and there are lots of ways that you can get
          involved.
          <br />
          <br />
          Members of our Intermediary Broker Support Admin Team recently spent time volunteering at Graves Park in
          Sheffield. The railings around the old boating lake hadn’t been painted in 20 years, and were in desperate
          need of some attention. As always, the team were more than happy to roll their sleeves up and get stuck in!
          <br />
          <br />
          After spending over 10 hours painting, the railings were restored and although the team ended up covered from
          head to toe in green paint, it was considered a job very well done. The park workers could not have been
          happier - in fact they were so impressed, they have been invited back again!
        </>
      ),
    },
    {
      image: HSBCCan,
      title: "",
      description: (
        <>
          HSBC UK's Climate Action Network recently worked with several colleagues to focus on a nationwide food drive,
          #1CAN.
          <br />
          <br />
          During the month of March, all staff were asked to donate one can of food at one of 10 participating offices
          to support their vulnerable local communities.
          <br />
          <br />
          Liz Kitney (Marketing Support Officer, HSBC UK Intermediary Mortgages) helped drive the #1CAN initiative at
          our Sheffield office. Liz also shared various ways to avoid food waste with staff, along with great
          eco-friendly plant-based recipes. Liz was key in collaborating with her local Trussell Trust food bank to
          collect donations, helping those who needed it the most.
        </>
      ),
    },
  ];

  return (
    <Layout title="Green" metaDescription="HSBC Intermediaries Green Page">
      <div className="home-header green row">
        <div className="green__hero">
          {/*  <HeroBanner /> */}
          <GreenHero onClick={(clickedText) => handleClick({ sectionName: "green hero", clickedText: clickedText })} />
        </div>
        <div className="green__card-container" style={{ marginBottom: "0px" }}>
          <div className="green-section" style={{ marginBottom: "0px", padding: "20px 30px" }}>
            <p style={{ color: "#707070" }}>
              Today, HSBC finances a number of industries that significantly contribute to greenhouse gas emissions. We
              have a strategy to help our customers to reduce their emissions and to reduce our own. For more
              information visit 
              <a
                target="_blank"
                href="http://www.hsbc.com/sustainability"
                onClick={() => handleClick({ sectionName: "green", clickedText: "www.hsbc.com/sustainability" })}
                style={{ textDecoration: "underline" }}
              >
                www.hsbc.com/sustainability
              </a>
              .
            </p>
          </div>
        </div>
        <div className="green__card-container">
          <GreenCard
            href="/energy-performance-certificate"
            onClick={() => handleClick({ sectionName: "green card", clickedText: "Energy performance certificate" })}
            title="Energy performance certificate"
            description="Information on EPC "
          />
          <GreenCard
            href="https://footprint.wwf.org.uk/#/"
            onClick={() =>
              handleClick({ sectionName: "green card", clickedText: "How big is your environmental footprint?" })
            }
            title="How big is your environmental footprint?"
            description="WWF environmental footprint calculator"
            isExternal={true}
          />
        </div>
        <GreenSectionWithCards
          title="Sustainability at home"
          description="Learn about new and existing technologies that can help reduce your customer's carbon footprint on their home. This could not only reduce energy bills but adding eco features could also increase their home's value. "
          cards={cardItems}
          isExternal={true}
          additionalClasses=""
          onClick={(clickedText) => handleClick({ sectionName: "sustainability at home", clickedText: clickedText })}
        />
        <GreenSectionWithCards
          title="Intermediary team contributions"
          description=""
          cards={cardItems2}
          additionalClasses="green-section__cards-container--custom"
          onClick={(clickedText) => handleClick({ sectionName: "sustainability at home", clickedText: clickedText })}
        />
        <GreenResources />
      </div>
    </Layout>
  );
}
