import React from "react";
import { Link } from "gatsby";
import ExternalLink from "../external-link/external-link.component";

const GreenSectionWithCards = ({
  title,
  description,
  cards,
  additionalClasses,
  onlyText,
  isExternal = false,
  onClick,
}) => {
  const LinkComponent = isExternal ? ExternalLink : Link;

  return (
    <div className="green-section">
      {title && <h2 className="green-section__title">{title}</h2>}
      {description && (
        <>
          <p className="green-section__description">{description}</p>
          {onlyText ? "" : <div className="extra-bottom-line"></div>}
        </>
      )}
      <div
        className={
          additionalClasses ? `green-section__cards-container ${additionalClasses}` : `green-section__cards-container`
        }
      >
        {cards &&
          cards.map((card, index) => (
            <div key={index} className="green-section__card">
              <img src={card.image} alt={card.title} className="green-section__card-image" />
              {card.title && (
                <LinkComponent
                  className="homepage-link-titles green-section__card-title"
                  to={card.href}
                  target="_blank"
                  onClick={() => onClick(card.title)}
                >
                  <h2 className="section__title">{card.title}</h2>
                </LinkComponent>
              )}

              {card.description && <p className="green-section__card-description">{card.description}</p>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default GreenSectionWithCards;
